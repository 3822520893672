import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import { useMutation } from 'urql';

import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout';
import { Role_ID, STATUS_CODE, COACHEE_ACTIONS, COURSE_STATUS, MaterialIconName } from '../../models/enum';
import { ICourse } from '../../models/type';
import { COURSE_start_course } from '../../services/apis/courseApis';
import styles from './onboardingPage.module.scss';
import HeroImage from '../../assets/images/onboarding_hero_image.jpg';

interface InfoProps {
  title: string;
  content: string;
}

interface CircleProps {
  number: number | string;
}

const Circle = ({ number }: CircleProps) => {
  return (
    <div className={styles.circle}>
      <div className={styles.innerCircle}>
        <span>{number}</span>
      </div>
      <div className={styles.triangle}></div>
    </div>
  );
};

const Info = ({ title, content }: InfoProps) => {
  const { t: translate } = useTranslation();
  return (
    <div className={styles.infoCard}>
      <div className={styles.head}>{translate(title)}</div>
      <div className={styles.body}>{translate(content)}</div>
    </div>
  );
};

const OnboardingPage = () => {
  const location = useLocation();
  const [helperState, setHelperState] = useState({ showMainContent: true });
  const selectedCourse: ICourse = useSelector((state: any) => state.coacheeReducer.selectedCourse);

  // /*METHODS*/
  const [startCourseResult, executeStartCourse] = useMutation(COURSE_start_course);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const goToProfile = () => navigate(`/${Role_ID.COACHEE}/profile`);

  const handleSubmit = useCallback(() => {
    if (!selectedCourse) {
      alert('Veuillez sélectionner un parcours');
      navigate(`/${Role_ID.COACHEE}`);
      return;
    }
    // Check has diagnosisyes go to diagnosis page OR no go to coach selection page
    if (selectedCourse.offer_configuration.has_self_diagnosis) {
      navigate(`/${Role_ID.COACHEE}/self-diagnostic`, { state: { selectedCourse: selectedCourse } });
      return;
    }
    navigate(`/${Role_ID.COACHEE}/coach-selection`, { state: { selectedCourse: location.state.selectedCourse } });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  /**
   * EFFECT for first time mouting component:
   *  - This is for in case user use url remember by browser or  refreshing page
   */
  useEffect(() => {
    /**IF NO selectedCourse push back to coachee course selection page */
    if (!location.state?.selectedCourse) {
      navigate(`/${Role_ID.COACHEE}`);
      return;
    }

    if (location.state?.selectedCourse.status === COURSE_STATUS.started_onboarding) return;

    //CALL API start course
    executeStartCourse({ course_id: location.state.selectedCourse.id })
      .then((res) => {
        if (res.data?.startCourse.status_code === +STATUS_CODE.SUCCESS) {
          dispatch({
            type: COACHEE_ACTIONS.update_course_status,
            payload: res.data.startCourse.course.status,
          });
          setHelperState({ ...helperState, showMainContent: true });
        } else if (res?.error.graphQLErrors[0].extensions.error_code === STATUS_CODE.BADREQUEST) {
          setHelperState({
            ...helperState,
            showMainContent: true,
          });
        } else {
          alert('Échouer');
          navigate(`/${Role_ID.COACHEE}`);
        }
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*MAIN RETURN*/
  return (
    <PrimaryLayout
      primaryTitle='Mes parcours'
      primaryTitleIcon={MaterialIconName.assignment}
      role_name={translate('Coachee')}
      onClickPrimaryTitle={() => navigate(`/${Role_ID.COACHEE}`)}
      toProfile={goToProfile}
      hideUserIcon={true}
      whiteBackground={true}
      goToMessage={() => navigate(`/${Role_ID.COACHEE}/course-management/message`)}>
      <Container>
        <img className={styles.heroImage} src={HeroImage} alt='hero_image' />
        <p className={styles.welcome}>
          Bienvenue sur <span>mypositivebooster</span> la <b>plateforme digitale</b> conçue pour vous <b>accompagner</b>{' '}
          tout au long de votre <b>parcours de développement.</b>
        </p>
      </Container>

      <div className={styles.container}>
        <h2 className={styles.title}>{translate('ONBOARDING.TITLE')}</h2>

        <div className={styles.stepContainer}>
          <div>
            <Circle number={1} />
            <Info title='ONBOARDING.ITEM1.TITLE' content='ONBOARDING.ITEM1.CONTENT' />
          </div>
          <div className={styles.rightIcon}>
            <div className={styles.arrowRight}></div>
          </div>
          <div>
            <Circle number={2} />
            <Info title='ONBOARDING.ITEM2.TITLE' content='ONBOARDING.ITEM2.CONTENT' />
          </div>
          <div className={styles.rightIcon}>
            <div className={styles.arrowRight}></div>
          </div>
          <div>
            <Circle number={3} />
            <Info title='ONBOARDING.ITEM3.TITLE' content='ONBOARDING.ITEM3.CONTENT' />
          </div>
          <div className={styles.rightIcon}>
            <div className={styles.arrowRight}></div>
          </div>
          <div>
            <Circle number={4} />
            <Info title='ONBOARDING.ITEM4.TITLE' content='ONBOARDING.ITEM4.CONTENT' />
          </div>
          <div className={styles.rightIcon}>
            <div className={styles.arrowRight}></div>
          </div>
          <div>
            <Circle number={5} />
            <Info title='ONBOARDING.ITEM5.TITLE' content='ONBOARDING.ITEM5.CONTENT' />
          </div>
          <div className={styles.rightIcon}>
            <div className={styles.arrowRight}></div>
          </div>
          <div className={styles.lastStep}>
            <Circle number={6} />
            <Info title='ONBOARDING.ITEM6.TITLE' content='ONBOARDING.ITEM6.CONTENT' />
          </div>
        </div>
      </div>
      <Container>
        <div className={styles.bottomBlock}>
          <Button className={styles.nextButton} onClick={handleSubmit}>
            <span className={`topLevelText buttonText`}>{translate('ONBOARDING.SUBMIT.BUTTON')}</span>
          </Button>
        </div>
      </Container>
      {startCourseResult.fetching && <AwaitingComp fluid={true} />}
    </PrimaryLayout>
  );
};

export default OnboardingPage;
