import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';

import UploadComponent from '../../components/UploadComponent/UploadComponent';
import styles from './programMangerListCoacheePage.module.scss';
import '../../theme/customedPagination.scss';
import '../../theme/customedMenu.scss';
import { COACHEE_list_coachee_from_program } from '../../services/apis/coacheeApis';
import {
  ListCoacheesFromProgramSortableColumn,
  PROGRAMMANGER_ACTIONS,
  SemanticICONS,
  SemanticICONS_color,
  SortDirection,
  STATUS_CODE,
} from '../../models/enum';
import { COACHEE_upload } from '../../services/apis/coacheeApis';
import AwaitingComp from '../../components/Common/AwaitingComp/AwaitingComp';
import CoacheeTable from './CoacheeTable';
import ToolBlockCoacheeTable from './ToolBlockCoacheeTable';
import { mapCoursesFromCoacheesList } from '../../services/ProgramManager/progression';

const ProgramMangerListCoacheePage: React.FC = () => {
  /*PROPS*/

  const programID = window.location.pathname.split('/').pop();

  const [listCoacheeQueryVariables, setListCoacheeQueryVariables] = useState({
    program_id: programID,
    count: 20,
    page: 1,
    order_by: ListCoacheesFromProgramSortableColumn.NAME,
    direction: SortDirection.DESC,
    search_name: '',
  });

  const [listCoacheeResult, reFetchCoacheeList] = useQuery({
    query: COACHEE_list_coachee_from_program,
    variables: listCoacheeQueryVariables,
    requestPolicy: 'cache-and-network',
  });

  const programManagerState = useSelector((state: any) => state.programManagerReducer);

  const { status } = programManagerState.programInfo;

  /*METHODS*/
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const [, executeUploadCoachee] = useMutation(COACHEE_upload);

  const handleSearchByCoacheeName = (coacheeName: string) => {
    setListCoacheeQueryVariables({ ...listCoacheeQueryVariables, search_name: coacheeName });
  };

  const handleChangePage = (pageNumber: number) => {
    setListCoacheeQueryVariables({
      ...listCoacheeQueryVariables,
      page: pageNumber,
    });
  };

  const handleUploadCoachee = useCallback(() => {
    executeUploadCoachee({
      program_id: programID,
      file: programManagerState.uploadComponent.files[0],
    })
      .then((res) => {
        if (res?.data.importCoacheesForProgram.status_code) {
          reFetchCoacheeList();
          dispatch({
            type: PROGRAMMANGER_ACTIONS.set_upload_file,
            payload: {
              files: [],
              iconName: SemanticICONS.checkmark,
              iconColor: SemanticICONS_color['green'],
              title: translate('Add / modify coachee'),
              titleDesc: translate('An excel file with 3 columns in this order'),
              titleDesc_bold: 'EMAIL, NOM, PRENOM',
              titleDescContinuous: translate('File is not allowed'),
            },
          });
        } else {
          alert(translate('Upload failed, please refresh the page and try again'));
        }
      })
      .catch((err) => console.error(err));
  }, [dispatch, executeUploadCoachee, programID, programManagerState.uploadComponent.files, reFetchCoacheeList, translate]);

  const handleSort = (order_by: ListCoacheesFromProgramSortableColumn, direction: SortDirection) => {
    setListCoacheeQueryVariables({
      ...listCoacheeQueryVariables,
      order_by,
      direction,
    });
  };

  useEffect(() => {
    if (listCoacheeResult?.data?.listCoacheesFromProgram.status_code !== STATUS_CODE.SUCCESS) return;

    dispatch({
      type: PROGRAMMANGER_ACTIONS.set_list_coachee,
      payload: listCoacheeResult.data.listCoacheesFromProgram,
    });

    if (programManagerState.coursesProgression.programID !== programID) {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.set_course_progress,
        payload: {
          programID: programID,
          courses: mapCoursesFromCoacheesList(listCoacheeResult.data.listCoacheesFromProgram.coachees),
        },
      });
    }
  }, [dispatch, listCoacheeResult.data, programID, programManagerState.coursesProgression.programID]);

  /*MAIN RETURN*/
  return (
    <div className={styles.wrapper}>
      <UploadComponent
        title={programManagerState.uploadComponent.title}
        titleDesc={programManagerState.uploadComponent.titleDesc}
        titleDesc_bold={programManagerState.uploadComponent.titleDesc_bold}
        titleDescContinuous={programManagerState.uploadComponent.titleDescContinuous}
        iconName={programManagerState.uploadComponent.iconName}
        iconColor={programManagerState.uploadComponent.iconColor}
        file={programManagerState.uploadComponent.files[0]}
        programStatus={status}
        uploadFunction={() => handleUploadCoachee()}
        setFileActionName={PROGRAMMANGER_ACTIONS.set_upload_file}
        cancelActionName={PROGRAMMANGER_ACTIONS.remove_upload_file}
      />
      {!!programManagerState.listOfCoacheePage.listCoachee.length && (
        <div className={styles.listWrapper}>
          <div>
            {/* Add total coachee later with api */}
            <span className={styles.title}>{`Liste de coachés`}</span>
            <p>{translate('Sort by date to see new coachee first')}</p>
          </div>

          <ToolBlockCoacheeTable
            programStatus={status}
            handleSearch={(value) => handleSearchByCoacheeName(value)}
            handleChangePage={(number) => handleChangePage(number)}
            handleRefectCoacheeList={reFetchCoacheeList}
          />

          <div className={styles.tableWrapper}>
            <CoacheeTable
              programStatus={status}
              coacheeArray={programManagerState.listOfCoacheePage.listCoachee}
              handleSort={(order_by, direction) => handleSort(order_by, direction)}
              currentOrderBy={listCoacheeQueryVariables.order_by}
              currentDirection={listCoacheeQueryVariables.direction}
            />
          </div>
        </div>
      )}
      {listCoacheeResult.fetching && <AwaitingComp fluid={true} />}
    </div>
  );
};

export default ProgramMangerListCoacheePage;
