import React, { useCallback, useEffect } from 'react';
import styles from './uploadComponent.module.scss';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { PROGRAMMANGER_ACTIONS, ProgramStatus, SemanticICONS, SemanticICONS_color } from '../../models/enum';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { maxUploadSize } from '../../config/helperConstant';

interface Props {
  title: string;
  titleDesc: string;
  titleDesc_bold: string;
  titleDescContinuous?: string;
  iconName: SemanticICONS;
  iconColor: SemanticICONS_color;
  file: any;
  setFileActionName: string;
  optionalPayload?: {
    iconName: SemanticICONS;
    iconColor: SemanticICONS_color;
  };
  cancelActionName: string;
  noBorderSegment?: boolean;
  hideButtonBlock?: boolean;
  programStatus?: string;
  uploadFunction: () => void;
  hasEditedFile?: () => void;
}

const UploadComponent: React.FC<Props> = (props: Props) => {
  /*PROPS*/

  /*METHODS*/
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const onDrop = (acceptedFiles: any) => {
    props.hasEditedFile && props.hasEditedFile();
    const files = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );

    const defaultPayload =
      files[0].size <= maxUploadSize
        ? {
            files: files,
            title: translate('Upload list coachees'),
            titleDesc: '',
            titleDesc_bold: '',
            titleDescContinuous: '',
            iconName: SemanticICONS['file excel outline'],
            iconColor: SemanticICONS_color['green'],
          }
        : {
            files: files,
            title: translate('Upload file cannot larger than 5MB'),
            titleDesc: '',
            titleDesc_bold: '',
            titleDescContinuous: '',
            iconName: SemanticICONS['exclamation triangle'],
            iconColor: SemanticICONS_color['red'],
          };

    dispatch({
      type: props.setFileActionName,
      payload: props.optionalPayload
        ? { ...props.optionalPayload, files: files }
        : {
            ...defaultPayload,
            title: props.title,
            titleDesc: props.titleDesc,
            titleDesc_bold: props.titleDesc_bold,
            titleDescContinuous: props.titleDescContinuous,
          },
    });
  };

  const handeCancelButton = useCallback(() => {
    dispatch({
      type: props.cancelActionName,
    });
  }, [dispatch, props.cancelActionName]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    return () => {
      dispatch({
        type: PROGRAMMANGER_ACTIONS.remove_upload_file,
      });
    };
  }, [dispatch]);

  return (
    <Segment fluid={true} className={`${styles.customedSegment} ${props.noBorderSegment && styles.noBorderSegment}`}>
      <span className={styles.title}>{props.title}</span>
      <p className={styles.titleDesc}>
        {props.titleDesc}
        <b>{' ' + props.titleDesc_bold}</b> {props.titleDescContinuous ? props.titleDescContinuous : ''}
      </p>
      <div className={styles.uploadWrapper} {...getRootProps()}>
        {isDragActive && <div className={styles.layer}></div>}
        <input {...getInputProps()} type='file' name='file' disabled={props.programStatus === ProgramStatus.DONE} />
        <Icon name={props.iconName} className={styles.uploadIcon} color={props.iconColor} />

        {!props.file && (
          <span className={styles.uploadDesc}>
            {translate('Drag and drop')} <span className={styles.textClickable}>{translate('Click here')}</span>
          </span>
        )}
        {props.file && props.file.size <= maxUploadSize && (
          <p className={`${styles.uploadFileName}`}>
            {props.file.name + ' '}
            <span className={styles.uploadFileDesc}>{translate('Ready to be sent')}</span>
          </p>
        )}
        {props.file && props.file.size > maxUploadSize && (
          <span className={styles.uploadFileDesc}>{translate('File is not allowed')}</span>
        )}
      </div>
      {!props.hideButtonBlock && (
        <div className={styles.btnGroup}>
          <Button
            className={`${styles.btn} ${styles.btnLeft} ${styles.btnUpload} ${
              props.file && props.file.size <= maxUploadSize && styles.uploadActive
            } `}
            disabled={!props.file || props.file.size > maxUploadSize}
            onClick={props.uploadFunction}>
            <span className={`topLevelText buttonText`}>{translate('Send')}</span>
          </Button>
          <Button className={`${styles.btn}  ${styles.btnCancel}`} onClick={handeCancelButton} disabled={!props.file}>
            <span className={`topLevelText buttonText`}>{translate('Cancel')}</span>
          </Button>
        </div>
      )}
    </Segment>
  );
};

export default React.memo(UploadComponent);
