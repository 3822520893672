import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { SESSION_ACTIONS } from '../../models/enum';
import { ISession } from '../../models/type';
import { COURSE_get_course } from '../../services/apis/courseApis';
import CoacheeSessionCard from '../CoacheeSessionCard/CoacheeSessionCard';
import styles from './coacheeUpcomingSession.module.scss';
const CoacheeUpcomingSession: React.FC = () => {
  /*PROPS*/

  const coursesOnStore = useSelector((state: any) => {
    return state.coacheeReducer.myCourseManagement;
  });

  /*METHODS*/

  const dispatch = useDispatch();

  const [getCourseResult, queryGetCourse] = useQuery({
    query: COURSE_get_course,
    variables: { course_id: window.location.search.slice(1) },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const renderSessionCards = (
    sessions: Array<ISession>,
    totalSessions: number,
    offerConfigName: string,
    clientName: string,
  ) => {
    if (!sessions) return;
    return sessions.map((item: ISession) => {
      return (
        <Grid.Row key={item.id}>
          <Grid.Column mobile='16' tablet='16' computer='13' widescreen='10' largeScreen='10'>
            <CoacheeSessionCard
              session={item}
              totalSession={totalSessions}
              offerConfigName={offerConfigName}
              clientName={clientName}
              reFetchGetCourse={queryGetCourse}
            />
          </Grid.Column>
        </Grid.Row>
      );
    });
  };

  /*EFFECTS*/

  /**
   * Effect for fetching latest course detail after canceling a session
   */
  useEffect(() => {
    if (!!getCourseResult.data) {
      dispatch({
        type: SESSION_ACTIONS.set_sessions,
        payload: getCourseResult?.data.getCourse.course,
      });
    }
  }, [dispatch, getCourseResult]);

  return (
    <div className={styles.wrapper}>
      <Grid>
        {renderSessionCards(
          coursesOnStore.myUpComingSession,
          coursesOnStore.myCourse?.sessions.length,
          coursesOnStore.myCourse?.offer_configuration.name,
          coursesOnStore.myCourse?.client.name,
        )}
      </Grid>
    </div>
  );
};

export default CoacheeUpcomingSession;
