import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'semantic-ui-react';
import styles from './toggleButton.module.scss';

interface Props {
  label?: string;
  disable?: boolean;
  checked?: boolean;
  handleChange?: () => void;
}

const ToggleButton: React.FC<Props> = (props: Props) => {
  //METHODS
  const { t: translate } = useTranslation();

  return (
    <div className={styles.container}>
      <Radio onChange={props.handleChange} checked={props.checked} toggle disabled={props.disable} />
      <label>{translate(props.label)}</label>
    </div>
  );
};

export default ToggleButton;
