import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';
import {
  generatePopUpMessageForAssignFollowUpButton,
  shouldDisableAssignFollowUpButton,
} from '../../../functions/tool-block-coachee-table';
import { IOfferConfiguration } from '../../../models/type';
import styles from './button.module.scss';

interface Props {
  disabled: boolean;
  handleClick: () => void;
  numbOfCourse: number;
}

const style = {
  borderRadius: 10,
  opacity: 0.9,
  padding: '2rem',
  backgroundColor: 'red',
  fontFamily: 'MulishSemiBold',
  color: 'white',
  minWidth: 150,
};

const AssignFollowUpButton: React.FC<Props> = (props: Props) => {
  const programOfferSelector: IOfferConfiguration[] = useSelector(
    (state: any) => state.programManagerReducer?.program?.offerConfigurations,
  );

  const { t: translate } = useTranslation();

  return (
    <Popup
      trigger={
        <div className={styles.wrapper}>
          <Button
            className={`${styles.btn} ${styles.btnAssign} `}
            disabled={shouldDisableAssignFollowUpButton(programOfferSelector, props.disabled)}
            onClick={props.handleClick}>
            <Icon name='calendar check outline' />
            <span>{translate('Assign follow-up course', { count: 2 })}</span>
          </Button>
        </div>
      }
      content={translate(generatePopUpMessageForAssignFollowUpButton(props.numbOfCourse))}
      basic
      style={style}
      disabled={!shouldDisableAssignFollowUpButton(programOfferSelector, props.disabled)}
    />
  );
};

export default AssignFollowUpButton;
