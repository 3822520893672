import { IEvaluateCourseRatingItem } from '../models/type';

export const CoachMediaResourcesTitle = new Map([
  ['pdf', 'Documents PDF'],
  ['web_link', 'Liens web'],
  ['video_link', 'Liens vidéo'],
  ['image', 'Images'],
]);

export const Coach_classify_decode = new Map([
  ['EXEC', 'Executive Coach'],
  ['MNGR', 'Coach Senior'],
  ['CDP', 'Coach'],
  ['CLB', 'Coach en devenir'],
]);

export const LANGUGAGE_map = new Map([
  ['fr', 'French'],
  ['en', 'English'],
  ['es', 'Spanish'],
  ['de', 'German'],
]);

export const coacheeSessionStatusTranslation_map = new Map([
  ['done', 'done'],
  ['coachee_is_absent', 'You were absent'],
  ['cancelled_by_coach', 'Cancelled by coach'],
  ['cancelled_by_coachee', 'Cancelled by you'],
  ['interrupted', 'interrupted'],
]);

export const domainName = window.location.origin;

export const mediaResourcesFormat = [
  {
    key: 'all',
    text: 'All',
    value: '',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'pdf',
    text: 'Pdf',
    value: 'pdf',
  },
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
];

export const mediaResourcesInputFormat = [
  {
    key: 'image',
    text: 'Image',
    value: 'image',
  },
  {
    key: 'pdf',
    text: 'PDF',
    value: 'pdf',
  },
  {
    key: 'video_link',
    text: 'Video link',
    value: 'video_link',
  },
  {
    key: 'web_link',
    text: 'Web link',
    value: 'web_link',
  },
];

export const calendlyAPIURL = 'https://api.calendly.com/users/me';

export const maxUploadSize = 5000000; //5MB

export const courseEvaluationQuestionsCollection: Array<IEvaluateCourseRatingItem> = [
  {
    question: 'Because of your performance',
    maxRating: 5,
    name: 'operational_performance',
  },
  {
    question: 'Because of work life quality',
    maxRating: 5,
    name: 'work_life_quality',
  },
  {
    question: 'Because of professional relationship',
    maxRating: 5,
    name: 'professional_relationships',
  },
  {
    question: 'Because of your leadership',
    maxRating: 5,
    name: 'leadership',
  },
  {
    question: 'Because of personal organization',
    maxRating: 5,
    name: 'personal_organization',
  },
];

export const monthLastAnswerIndex = 12;

export const MONTH_LABEL = 'month';

export const YEAR_LABEL = 'year';
