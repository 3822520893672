import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { SESSION_ACTIONS, SESSION_STATUS, UserRoles } from '../../models/enum';
import InsessionComp from '../InsessionComp/InsessionComp';
import NoteReviewComp from '../NoteReviewComp/NoteReviewComp';
import styles from './coacheeCurrentSession.module.scss';
import { getUserRoleID } from '../../utilities/helper';
import { useQuery } from 'urql';
import { COURSE_get_course } from '../../services/apis/courseApis';
import CoacheeSessionCard from '../CoacheeSessionCard/CoacheeSessionCard';
import AlertModal from '../Common/AlertModal/AlertModal';
import { useTranslation } from 'react-i18next';
import { ISession } from '../../models/type';
import { shouldDisplaySetLinkReminder } from '../../functions/session-card';

const CoacheeCurrentSession: React.FC = () => {
  /* State */
  const [showAdviseSetConfLinkModal, setShowAdviseSetConfLinkModal] = useState(false);
  const [timesShowAdviseConfLink, setTimesShowAdviseConfLink] = useState(0);
  const courseID = useMemo(() => window.location.search.slice(1), []);

  /*PROPS*/

  const courseStore = useSelector((state: any) => state.coacheeReducer.myCourseManagement);

  /*METHODS*/
  const dispatch = useDispatch();

  const [getCourseResult, queryGetCourse] = useQuery({
    query: COURSE_get_course,
    variables: { course_id: courseID },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const { t: translate } = useTranslation();

  const onCancel = () => {
    setShowAdviseSetConfLinkModal(false);
    setTimesShowAdviseConfLink(1);
  };

  const renderAlertModal = () => {
    if (showAdviseSetConfLinkModal && timesShowAdviseConfLink < 1) {
      return (
        <AlertModal
          title={translate('Please set up conference link')}
          contentQuestion={translate('You have scheduled a coaching session. Please set up a conference link')}
          content={translate('You can set up conference link by yourself or ask your coach to do that for you')}
          hideCloseButton={true}
          agree={onCancel}
          cancel={onCancel}
        />
      );
    }

    return <></>;
  };

  /*EFFECTS*/

  /**
   * Effect for fetching latest course detail after canceling a session
   */
  useEffect(() => {
    if (!!getCourseResult.data) {
      dispatch({
        type: SESSION_ACTIONS.set_sessions,
        payload: getCourseResult?.data.getCourse.course,
      });
    }
  }, [dispatch, getCourseResult]);

  useEffect(() => {
    const currentSession: ISession = courseStore?.myCurrentSession;

    if (!!currentSession === false) return;

    if (
      shouldDisplaySetLinkReminder(
        currentSession.planned_at,
        currentSession.videoconference_link,
        currentSession.is_offline,
      )
    )
      setShowAdviseSetConfLinkModal(true);
  }, [courseStore]);

  /*MAIN RETURN*/
  if (!courseStore.myCurrentSession) return <></>;

  if (
    [SESSION_STATUS.started_waiting, SESSION_STATUS.started].includes(courseStore.myCurrentSession.status) &&
    courseStore.myCurrentSession.attendees.includes(getUserRoleID(UserRoles.COACHEE))
  ) {
    return (
      <div className={styles.wrapper}>
        <InsessionComp queryGetCourse={queryGetCourse} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile='16' tablet='16' computer='13' widescreen='10' largeScreen='10'>
            <CoacheeSessionCard
              session={courseStore.myCurrentSession}
              totalSession={courseStore.myCourse.sessions.length}
              offerConfigName={courseStore.myCourse.offer_configuration.name}
              clientName={courseStore.myCourse.client.name}
              reFetchGetCourse={queryGetCourse}
            />
          </Grid.Column>
        </Grid.Row>
        {courseStore.myDoneSessions.length && (
          <Grid.Row>
            <Grid.Column mobile='16' tablet='16' computer='13' widescreen='10' largeScreen='10'>
              <NoteReviewComp
                session={
                  !!courseStore.myDoneSessions.length &&
                  courseStore.myDoneSessions[courseStore.myDoneSessions.length - 1]
                }
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      {renderAlertModal()}
    </div>
  );
};

export default CoacheeCurrentSession;
