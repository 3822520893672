export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum ClientOrderableColumn {
  NAME = 'NAME',
  TOOL = 'TOOL',
  SUBDOMAIN = 'SUBDOMAIN',
  PROJECT_DIRECTOR = 'PROJECT_DIRECTOR',
}

export enum ProgramOrderableColumn {
  PROGRAM_NAME = 'PROGRAM_NAME',
  CLIENT_NAME = 'CLIENT_NAME',
  NUMBER_OF_COURSES = 'NUMBER_OF_COURSES',
  NUMBER_OF_PROGRAM_MANAGERS = 'NUMBER_OF_PROGRAM_MANAGERS',
  NUMBER_OF_COACHES = 'NUMBER_OF_COACHES',
  NUMBER_OF_COACHEES = 'NUMBER_OF_COACHEES',
}

export enum ListCoacheesFromProgramSortableColumn {
  CREATED_AT = 'CREATED_AT',
  DEPARTMENT = 'DEPARTMENT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  NUMBER_OF_SESSIONS_DONE = 'NUMBER_OF_SESSIONS_DONE',
  IS_STARTED = 'IS_STARTED',
  OFFER_NAME = 'OFFER_NAME',
}

export enum ListMyCoursesOrderBy {
  coachee = ' coachee',
  client = 'client',
  anomaly = 'anomaly',
  last_change = 'last_change',
  session_index = 'session_index',
  offerCourseContext = 'offerCourseContext',
}

export enum ListMyCourseSearchBy {
  coachee_name = 'coachee_name',
}

export enum FormBehavior {
  'RESET_PASSWORD' = '/reset-password',
  'ACTIVATE_ACCOUNT' = '/activate-account',
}

export enum UserRoles {
  STAFF = 'staff',
  COACH = 'coach',
  COACHEE = 'coachee',
  PROGRAM_MANAGER = 'program manager',
  PROJECT_DIRECTOR = 'project director',
}

/*Temporary Role_ID*/
export enum Role_ID {
  STAFF = 'Role_ID_staff',
  COACH = 'Role_ID_coach',
  COACHEE = 'Role_ID_coachee',
  PROGRAM_MANAGER = 'Role_ID_program_manager',
  PROJECT_DIRECTOR = 'Role_ID_project_director',
}
/******** */

export enum SemanticICONS {
  'bell' = 'bell',
  'clipboard list' = 'clipboard list',
  'cloud upload' = 'cloud upload',
  'file excel outline' = 'file excel outline',
  'checkmark' = 'checkmark',
  'file pdf outline' = 'file pdf outline',
  'check' = 'check',
  'eye' = 'eye',
  'image outline' = 'image outline',
  'linkify' = 'linkify',
  'video' = 'video',
  'exclamation triangle' = 'exclamation triangle',
}

export enum SemanticICONS_color {
  'grey' = 'grey',
  'green' = 'green',
  'blue' = 'blue',
  'red' = 'red',
}

export enum STATUS_CODE {
  SUCCESS = '200',
  NOCONTENT = '204',
  UNAUTHORIZED = 401,
  NOTFOUND = 404,
  BADREQUEST = 400,
  FORBIDDEN = 403,
  UNPROCESSABLE = 422,
}

/*DIAGNOSIS QUESTION TYPE*/
export enum DIAGNOSIS_COLLECTION_TYPE {
  public = 'public',
  logic = 'logic',
}

/*REDUX*/
export enum USER_ACTIONS {
  set_user_name = 'set_user_name',
}

export enum PROGRAMMANGER_ACTIONS {
  set_program_info_viewing = 'set_program_info_viewing',
  set_list_coachee = 'set_list_coachee',
  select_coachee = 'select_coachee',
  set_upload_file = 'set_upload_file',
  set_course_progress = 'set_course_progress',
  remove_upload_file = 'remove_upload_file',
  upload_coachee = 'upload_coachee_to_program',
  clear_store = 'clear_store',
  clear_selected_coachee = 'clear_selected_coachee',
  assign_coachee_to_course = 'assign_coachee_to_course',
  unassign_coachee_from_course = 'unassign_coachee_from_course',
  set_program_info = 'set_program_info',
}

export enum HELPER_ACTIONS {}

/*COACHEE*/
export enum COACHEE_ACTIONS {
  select_course = 'select_course',
  update_course_status = 'update_course_status',
  choose_self_diagnosis_answer = 'choose_self_diagnosis_answer',
  delete_all_answer = 'delete_all_answer',
  hide_button = 'hide_form_submit_button',
  init_self_diagnosis = 'init_self_diagnosis',
  answer_diagnosis = 'answer_diagnosis',
  back_to_previous_question = 'back_to_previous_question',
  finish_self_diagnostic = 'finish_self_diagnostic',
  clear_self_diagnostic = 'clear_self_diagnostic',
  update_video_conference_link = 'update_video_conference_link_coachee',
  switch_session_type = 'coachee_switch_session_type',
}

/*COURSE*/
export enum COURSE_STATUS {
  'unassigned' = 'unassigned',
  'open' = 'open',
  'started_onboarding' = 'started_onboarding',
  'started_self_diagnosis' = 'started_self_diagnosis',
  'started_coach_selection' = 'started_coach_selection', //AT coach selection page
  'started' = 'started', //AFTER coach selection success status
  'paused' = 'paused',
  'closed' = 'closed',
  'interrupted' = 'interrupted',
}

export enum OFFER_NAME {
  focus = 'Coaching focus',
  flash = 'Appui Flash',
  development = 'Coaching de développement',
}

export enum OFFER_NAME_Capitalize {
  focus = 'Coaching Focus',
  flash = 'Appui Flash',
  development = 'Coaching de Développement',
}

/*COURSE*/
export enum COURSE_ACTIONS {
  update_objectives = 'update_objectives',
}

/*SESSION*/
export enum SESSION_STATUS {
  'unplanned' = 'unplanned',
  'planned' = 'planned',
  'started_waiting' = 'started_waiting',
  'started' = 'started',
  'done' = 'done',
  // CANCELLATIONS
  'cancelled_by_coach' = 'cancelled_by_coach',
  'cancelled_by_coachee' = 'cancelled_by_coachee',
  'cancel_waiting' = 'cancel_waiting', //use for localstorage check session cancellation
  // ABSENCES
  'coach_is_absent' = 'coach_is_absent',
  'coachee_is_absent' = 'coachee_is_absent',
  'both_are_absent' = 'both_are_absent',
}

export enum SESSION_ACTIONS {
  set_sessions = 'set_sessions',
  update_status = 'update_status',
  update_show_end_button = 'update_show_end_button',
  write_note = 'write_note',
  save_planned_session = 'save_planned_session',
  coachee_set_done_sessions = 'coachee_set_done_sessions',
  coachee_add_new_done_session = 'coachee_add_new_done_session',
}

export enum SESSION_CONSTANT {
  buffer_time = 10, //In minutes
  absent_buffer_time = 15, //In minutes
  requery_time = 30000, //30 seconds
  max_star = 5, //Max session evaluation stars
  dealine_before_cancel = 1440, //24hours
  one_minute_in_miliseconds = 60 * 1000,
}

/*COACH*/
export enum COACH_Actions {
  'set_media_resources' = 'set_media_resources',
  'set_my_sessions' = 'set_my_sessions',
  'set_my_finished_sessions' = 'set_my_finished_sessions',
  'set_upload_photo' = 'set_upload_photo',
  'clear_upload' = 'clear_upload',
  'clear_media_resources' = 'clear_media_resources',
  'select_session_to_join' = 'select_session_to_join',
  'write_note' = 'write_note',
  'clear_select_session' = 'clear_select_session',
  'update_ongoing_session' = 'update_ongoing_session',
  'update_video_conference_link' = 'update_video_conference_link',
  'switch_session_type' = 'coach_switch_session_type',
}

export enum MESSAGE_Actions {
  'set_messages' = 'set_messages',
  'add_new_message' = 'add_new_message',
  'clear_message_store' = 'clear_message_store',
}

export enum ListMediaResourcesSortableColumn {
  title = 'title',
  format = 'format',
  created_at = 'created_at',
  updated_at = 'updated_at',
  language = 'language',
}

/*Media Resources*/
export enum MediaResourcesSearchBy {
  title = 'title',
  keyword = 'keyword',
}

export enum MediaResourcesFormats {
  image = 'image',
  pdf = 'pdf',
  video_link = 'video_link',
  web_link = 'web_link',
}

export enum LANGUAGES_Translation {
  'fr' = 'French',
  'en' = 'English',
  'es' = 'Spanish',
  'de' = 'German',
}

export enum CERTIFICATIONS {
  AAC = 'AAC',
  MCC = 'MCC',
  PCC = 'PCC',
  OTHER = 'Other',
}

export enum LANGUAGE_Code {
  'fr' = 'fr',
  'en' = 'en',
  'es' = 'es',
  'de' = 'de',
}

export enum CourseAnomalies {
  none = 'none',
  warning = 'warning',
  error = 'error',
}

export enum CalendlyStatus {
  active = 'active',
  canceled = 'canceled',
}

export enum FormMessages {
  'Success' = 'Success',
  'Failed' = 'Failed',
}

export enum MaterialIconName {
  'assignment' = 'assignment',
  'send' = 'send',
  'video_call' = 'video_call',
}

export enum ProgramStatus {
  'NOT_READY' = 'NOT_READY',
  'ACTIVE' = 'ACTIVE',
  'DONE' = 'DONE',
}
